export interface ISource {
    id?: string,
    code: number,
    name: string
}

export interface IExpect {
    id?: string,
    code?: number,
    name: string
}

export interface INote {
    id?: string,
    code?: number,
    name: string
}

export interface ILog {
    _id?: string,
    tch_ticket_id?: string,
    hrw_ticket_id?: string,
    data?: string,
    status: ELogStatus,
    created_at?: Date
}

export enum ELogStatus {
    SUCCESS = 1,
    FAILURE = 2
}