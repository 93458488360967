import React, { Component } from 'react';
import { Layout, Button } from 'antd';
import config from '../../config'
import { RouteComponentProps } from 'react-router';

interface LogoutProps {

}

export class Logout extends React.Component<RouteComponentProps<LogoutProps>, {}> {
    constructor(props) {
        super(props);

        this.state = {}
    }

    componentDidMount() {
        debugger
    }

    goLogin() {
        window.location.href = config.buildLoginHrUrl
    }
    render() {
        return (
            <Layout style={{ background: 'transparent' }}>
                <div className="main-container">
                    <div className="wrap-box">
                        <div className="wrapper-content">
                            <div className="ui-main-section-header ">
                                <a href="//www.haravan.com">
                                    <img className="padding-left-8" alt='haravan-logo' style={{ height: '40px' }} src='/images/logo.png' />
                                </a>
                            </div>
                            <div className="ui-main-section-body">
                                <div className="text-bold" style={{ fontSize: 25 }}>
                                    Đăng xuất
                  </div>
                                <div>
                                    Bạn có muốn đăng xuất?
                  </div>
                                <div style={{ marginTop: '20px' }}>
                                    {/* <HrvComponents.Button status="default" onClick={this.goLogin.bind(this)}>Đăng nhập lại</HrvComponents.Button> */}
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </Layout>
            // </AdminLayout>
        );
    }
}
