import * as React from 'react';
import * as classnames from 'classnames';
import { Modal as ModalStrap } from 'reactstrap';
import './index.css'

export declare type optionSize = 'lg' | 'md' | 'sm';

interface IModalProps {
    isOpen?: boolean,
    size?: optionSize,
    className?: string,
    iconClose?: boolean,
    headerContent?: React.ReactNode,
    bodyContent?: React.ReactNode,
    bodyNotPadding?: boolean,
    footerContent?: React.ReactNode,
    footerDisabledCloseModal?: boolean,
    isBtnClose?: boolean,
    beforeShowModal?: Function,
    afterCloseModal?: Function,
    backdrop?: any, // true, false, 'static'
    keyboard: boolean,
    centered: boolean
}

interface IModalStates {
    isOpen: boolean
}

export class Modal extends React.Component<IModalProps, IModalStates> {
    constructor(props: any) {
        super(props);
        this.state = {
            isOpen: this.props.isOpen || false
        }

        this.handleOnClick = this.handleOnClick.bind(this);
    }

    static defaultProps = {
        isOpen: false,
        size: 'md',
        iconClose: true,
        isBtnClose: true,
        backdrop: 'static',
        keyboard: true,
        centered: false
    }

    componentWillReceiveProps(nextProps: any) {
        if (this.props.isOpen != nextProps.isOpen) {
            this.setState({ isOpen: nextProps.isOpen }, () => {
                if (this.state.isOpen && this.props.beforeShowModal)
                    this.props.beforeShowModal()
                if (this.props.footerDisabledCloseModal && !this.state.isOpen && this.props.afterCloseModal)
                    this.props.afterCloseModal()
            })
        }
    }

    public handleOnClick() {
        this.setState({
            isOpen: !this.state.isOpen
        }, () => {
            if (this.state.isOpen && this.props.beforeShowModal)
                this.props.beforeShowModal()
            if (!this.state.isOpen && this.props.afterCloseModal)
                this.props.afterCloseModal()
        })
    }

    public render() {
        const { isOpen, className, iconClose, headerContent, bodyContent, bodyNotPadding, footerContent, footerDisabledCloseModal,
            isBtnClose, beforeShowModal, afterCloseModal, children, size, backdrop, keyboard, centered, ...others } = this.props;
        let classes = classnames({
            'hrv-modal': true,
            [className]: className
        });

        return <React.Fragment>
            <ModalStrap isOpen={this.state.isOpen} toggle={this.handleOnClick} className={classes} size={size}
                backdrop={backdrop} keyboard={keyboard} centered={centered}>
                <div className='hrv-modal-header'>
                    <h4 className='hrv-modal-title'>{headerContent}</h4>
                    {iconClose &&
                        <span className='hrv-close' onClick={this.handleOnClick}>
                            {/* <HrvComponents.Icon size={20} type='times' /> */}
                        </span>
                    }
                </div>
                <div className={'hrv-modal-body' + (bodyNotPadding ? 'hrv-modal-body-pd-0' : '')}>{bodyContent}</div>
                {(footerContent || isBtnClose) &&
                    <div className='hrv-modal-footer'>
                        {/* {isBtnClose && !footerDisabledCloseModal && <HrvComponents.Button status='default' onClick={this.handleOnClick}>Hủy</HrvComponents.Button>} */}
                        {footerDisabledCloseModal
                            ? <div className='col-auto'>{footerContent}</div>
                            : <div className='col-auto' onClick={this.handleOnClick}>{footerContent}</div>}
                    </div>}
            </ModalStrap>
            <div {...others} onClick={this.handleOnClick}>{children}</div>
        </React.Fragment>
    }
}