import React, { Component } from 'react';
import { BrowserRouter, Route, Router, Switch } from 'react-router-dom';
// import Middlewware from './modules/middleware'
import * as Utils from './utils/env'
import * as RoutesModule from './routes';
import './App.css'

let router = RoutesModule.routes
const history = Utils.history

class App extends Component {
    render() {
        return <BrowserRouter children={router} basename={Utils.BASE_URL} />
    }
}

export default App;