import { createBrowserHistory } from 'history'
import config from '../config'
import * as _ from 'lodash'
import * as Icon from './Icon'
export { Icon }

export const history = createBrowserHistory();
export const token = localStorage.getItem('AccessToken');
export const HEADER_FORMDATA = 'application/x-www-form-urlencoded';

export const BASE_URL = '/'

let env = {
    baseUrl: BASE_URL,
    layout: null
}

export const getEntryPoint = () => {
    return config.entrypoint
}

export const getNewGuid = (): string => {
    const s4 = () => {
        return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1)
    }

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
}

// let data = {};
// let url = {};
// let method = '';

// const getBaseUrl = (url) => {
//     if (url.indexOf(config.backend_url) === -1) {
//         url = config.backend_url + url;
//     }
//     return url;
// }

// const Utils = {
//     api_call: () => {
//         return
//     },
//     api_call_post: (path, body, callbackSuccess, callbackError) => {
//         return BaseModel.post(path, body, callbackSuccess, callbackError)
//     }
// }

// const setData = (urlMake, dataMake, methodMake) => {
//     data = urlMake;
//     url = dataMake;
//     method = methodMake;
// }

// const instance = axios.create({
//     timeout: 300000,
//     headers: {
//         'AccessToken': token,
//         'Content-Type': 'application/json; charset=utf-8',
//     },
// });

// const make = (suc, err, fin) => {
//     let obj = {
//         method: method,
//         url: getBaseUrl(url)
//     };
//     if (method === 'get') {
//         obj['params'] = data;
//     } else {
//         obj['data'] = data;
//     }

//     instance(obj)
//         .then(function (response) {
//             if (suc) {
//                 suc(response)
//             }
//         })
//         .catch((error) => {
//             let status = _.get(error, 'response.status', 0);
//             console.log(error);
//             if (status === 401) {
//                 window.location.href = config.buildLoginHrUrl
//             }
//             // handle error
//             if (err) {
//                 err(error);
//             }
//         })
//         .then(function () {
//             if (fin) {
//                 fin();
//             }
//         });
// }

// const postData = (url, data, suc, err, fin) => {
//     setData(data, url, 'post');
//     make(suc, err, fin);
// }
// const getData = (url, data, suc, err, fin) => {
//     setData(data, url, 'get');
//     make(suc, err, fin);
// }
// const putData = (url, data, suc, err, fin) => {
//     setData(data, url, 'put');
//     make(suc, err, fin);
// }
// const deleteData = (url, data, suc, err, fin) => {
//     setData(data, url, 'delete');
//     make(suc, err, fin);
// }

const api_call_internal = async (method: string, body: any, path: string, errorSystem: string): Promise<any> => {
    let url = `${config.backend_url}api/${path}`;
    try {
        let rp = await fetch(url, {
            method: method,
            body: body ? JSON.stringify(body) : null,
            // credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AccessToken': token
            }
        });

        if (rp.status == 401) {
            if (env.layout)
                env.layout.openPopupAuthorizeException();
        } else if (rp.status == 403) {
            if (env.layout)
                env.layout.redirectPagePermission()
        } else if (rp.status == 200 || rp.status == 201 || rp.status == 304) {
            return await rp.json();
        } else if(rp.status == 404) {
            let ty = await rp.json()
        }
        else {
            if (errorSystem && env.layout)
                env.layout.ShowMessage('error', errorSystem)
        }
    }
    catch (e) {
        if (env.layout) {
            env.layout.ShowMessage('error', 'Xin lỗi, đã có lỗi khi thực hiện');
        }

        throw e;
    }
    finally {

    }
}


const api_call = async (method: string, body: any, path: string, errorSystem: string): Promise<any> => {
    let url = `${config.backend_url}api/hara-work/${path}`;
    try {
        let rp = await fetch(url, {
            method: method,
            body: body ? JSON.stringify(body) : null,
            // credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AccessToken': token
            }
        });
        if (rp.status == 401) {
            if (env.layout)
                env.layout.openPopupAuthorizeException();
        } else if (rp.status == 403) {
            if (env.layout)
                env.layout.redirectPagePermission()
        } else if (rp.status == 200 || rp.status == 201 || rp.status == 304) {
            return await rp.json();
        } else {
            if (errorSystem && env.layout)
                env.layout.ShowMessage('error', errorSystem)
        }
    }
    catch (e) {
        if (env.layout) {
            env.layout.ShowMessage('error', 'Xin lỗi, đã có lỗi khi thực hiện');
        }

        throw e;
    }
    finally {

    }
}

export const getAvatarUser = (userId: string | number) => {
    return 'call/hr_api/colleague/avatar/' + userId.toString()
}

export const api_call_get = (path: string, errorSystem: string): Promise<any> => {
    return api_call('GET', null, path, errorSystem);
}

export const api_call_post = (path: string, errorSystem: string, body: any): Promise<any> => {
    return api_call('POST', body, path, errorSystem);
}

export const api_call_put = (path: string, errorSystem: string, body: any): Promise<any> => {
    return api_call('PUT', body, path, errorSystem);
}

export const api_call_delete = (path: string, errorSystem: string): Promise<any> => {
    return api_call('DELETE', null, path, errorSystem);
}

export const api_call_get_internal = (path: string, errorSystem: string): Promise<any> => {
    return api_call_internal('GET', null, path, errorSystem);
}

export const api_call_post_internal = (path: string, errorSystem: string, body: any): Promise<any> => {
    return api_call_internal('POST', body, path, errorSystem);
}

export const api_call_put_internal = (path: string, errorSystem: string, body: any): Promise<any> => {
    return api_call_internal('PUT', body, path, errorSystem);
}

export const api_call_delete_internal = (path: string, errorSystem: string, body: any): Promise<any> => {
    return api_call_internal('DELETE', body, path, errorSystem);
}
