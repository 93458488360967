import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu, Icon } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actSelectMenuItem } from '../../actions/admin/admin-menu-left';
import PropTypes from 'prop-types';
import config from '../../config.js'
const propTypes = {
  selectedItem: PropTypes.string.isRequired
}

const { Sider } = Layout;
class AdminMenuLeft extends Component {

  handleSelectMenuItem = () => {
    // switch (window.location.pathname) {
    //   case '/' + config.pathAdmin + 'mapping':
    //     this.props.actSelectMenuItem(2);
    //     break;
    //   case '/' + config.pathAdmin + 'salary':
    //     this.props.actSelectMenuItem(3);
    //     break;
    //   default:
    //     this.props.actSelectMenuItem(1);
    //     break;
    // }
    const location = window.location.pathname.split('/')[2]
    if (!location[2]) {
      this.props.actSelectMenuItem('admin')
    } else {
      this.props.actSelectMenuItem(location);
    }
  }

  componentDidMount() {
    this.handleSelectMenuItem();
  }

  render() {
    return (
      <Sider className='bg-left' width={220}>
        <Menu
          style={{ background: '#e9ebee' }}
          selectedKeys={[this.props.selectedItem]}
        >
          {/* <Menu.Item key='mapping' onClick={this.handleSelectMenuItem}>
            <Link to={'/' + config.pathAdmin + 'mapping'}>
              <Icon type='trophy' />
              <span>Mapping cửa hàng</span>
            </Link>
          </Menu.Item> */}
          <Menu.Item key='jobtitlelevel' onClick={this.handleSelectMenuItem}>
            <Link to={'/' + config.pathAdmin + 'jobtitlelevel'}>
              <Icon type='trophy' />
              <span>Cấp bậc chức danh</span>
            </Link>
          </Menu.Item>
          <Menu.Item key='salaryelement' onClick={this.handleSelectMenuItem}>
            <Link to={'/' + config.pathAdmin + 'salary'}>
              <Icon type='pie-chart' />
              <span>Thành phần lương</span>
            </Link>
          </Menu.Item>
          <Menu.Item key='bonuspoint' onClick={this.handleSelectMenuItem}>
            <Link to={'/' + config.pathAdmin + 'bonuspoint'}>
              <Icon type='trophy' />
              <span>Tỷ lệ thưởng</span>
            </Link>
          </Menu.Item>
          <Menu.Item key='bonuspointother' onClick={this.handleSelectMenuItem}>
            <Link to={'/' + config.pathAdmin + 'bonuspointother'}>
              <Icon type='trophy' />
              <span>Thưởng ngoài hệ thống</span>
            </Link>
          </Menu.Item>
          <Menu.Item key='bonus' onClick={this.handleSelectMenuItem}>
            <Link to={'/' + config.pathAdmin + 'bonus'}>
              <Icon type='trophy' />
              <span>Tính thưởng</span>
            </Link>
          </Menu.Item>
        </Menu>
      </Sider>
    );
  }
}

AdminMenuLeft.propTypes = propTypes;

const mapStatesToProps = state => ({
  selectedItem: state.selectedAdminMenuItem
})
const mapDispatchToProps = dispatch => ({
  actSelectMenuItem: bindActionCreators(actSelectMenuItem, dispatch)
})

export default connect(mapStatesToProps, mapDispatchToProps)(AdminMenuLeft);
