import React, { Component } from 'react';
import {Modal, Select, Col} from 'antd';
import PropTypes from 'prop-types';
import lodash from 'lodash';

const propsTypes = {
  store: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      open_day: PropTypes.string,
      brand: PropTypes.string.isRequired
    })
  ).isRequired, // store nhan vao phai la array .isRequied la yeu cau phai co
  department: PropTypes.arrayOf(
    PropTypes.shape({
      id                  : PropTypes.number.isRequired,
      code                : PropTypes.string.isRequired,
      name                : PropTypes.string.isRequired,
      parentId            : PropTypes.number,
      status              : PropTypes.number,
      parentName          : PropTypes.string,
      description         : PropTypes.string,
      departmentUnitId    : PropTypes.number.isRequired,
      departmentUnitName  : PropTypes.string.isRequired,
      managerJobtitleId   : PropTypes.number,
      managerJobtitleName : PropTypes.string,
      address             : PropTypes.string,
      jobtitles           : PropTypes.arrayOf(PropTypes.string),
      paths               : PropTypes.string,
      timeZoneId          : PropTypes.number.isRequired,
      timeZone            : PropTypes.string,
      establishedDate     : PropTypes.string,
      orgId               : PropTypes.number.isRequired,
      count               : PropTypes.number.isRequired,
      isHr                : PropTypes.bool,
      otherName           : PropTypes.string
    })
  ),
  selectedDepartment: PropTypes.number.isRequired,
  selectedStore: PropTypes.number.isRequired,
  visible: PropTypes.bool.isRequired,
  confirmLoading: PropTypes.bool.isRequired,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  isEditting: PropTypes.bool.isRequired,
  handleChangeSelectStore: PropTypes.func.isRequired,
  handleChangeSelectDepartment: PropTypes.func.isRequired
}

//======================= Select ===================================//
const Option = Select.Option;
// function handleChange(value) {
//   console.log(`selected ${value}`);
// }
function handleBlur() {
  // console.log('blur');
}
function handleFocus() {
  // console.log('focus');
}
//===================== End Select ================================//

class MappingModal extends Component {
  render() {
    const {
      visible, 
      confirmLoading, 
      handleOk, 
      handleCancel, 
      store, 
      department, 
      isEditting, 
      handleChangeSelectStore, 
      handleChangeSelectDepartment,
      selectedDepartment, 
      selectedStore
    } = this.props;
    
    return (
      <Modal title={isEditting ? 'Sửa Mapping' : 'Thêm Mapping'}
            visible={visible}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
          >
            <div className="padding-bottom-15">
              <Col className="gutter-row" span={12}>
                <div className="gutter-box margin-right-7">
                <label>Cửa hàng *</label>
                  {selectedStore !== 0 &&
                    <Select
                      showSearch
                      defaultValue={selectedStore}
                      className="width-100-per"
                      optionFilterProp="children"
                      onChange={handleChangeSelectStore}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {store && store.map(s => 
                        <Option key={s.id + "#" + s.brand} value={s.id + "#" + s.brand}>{s.name} - [{s.brand}]</Option>
                      )}
                    </Select>
                  }
                  {selectedStore === 0 &&
                    <Select
                      showSearch
                      className="width-100-per"
                      placeholder="Cửa hàng"
                      optionFilterProp="children"
                      onChange={handleChangeSelectStore}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {store && store.map(s => 
                        <Option key={s.id + "#" + s.brand} value={s.id + "#" + s.brand}>{s.name} - [{s.brand}]</Option>
                      )}
                    </Select>
                  }
                </div>
              </Col>
              <Col className="gutter-row" span={12}>
                <div className="gutter-box margin-left-7">
                <label>Đơn vị *</label>
                {selectedDepartment !== 0 &&
                  <Select
                      showSearch
                      defaultValue={selectedDepartment !== 0 && selectedDepartment}
                      className="width-100-per"
                      placeholder="Đơn vị"
                      optionFilterProp="children"
                      onChange={handleChangeSelectDepartment}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {department && department.map(d => 
                        <Option key={d.id} value={d.id}>{d.name} - [{d.parentName}]</Option>
                      )}
                    </Select>
                }
                {selectedDepartment === 0 &&
                  <Select
                    showSearch
                    className="width-100-per"
                    placeholder="Đơn vị"
                    optionFilterProp="children"
                    onChange={handleChangeSelectDepartment}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {department && department.map(d => 
                      <Option key={d.id} value={d.id}>{d.name} - [{d.parentName}]</Option>
                    )}
                  </Select>
                }
                </div>
              </Col>
            </div>
            <br />
          </Modal>
    );
  }
}

MappingModal.propTypes = propsTypes;

export default MappingModal;