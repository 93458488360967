import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
// import App from 'modules/App';
import App from './App'
import * as serviceWorker from './serviceWorker';
import configureStore from './store';
const store = configureStore();


// ReactDOM.render(
//   <Provider store={store}>
//     <App />
//   </Provider>,
//   document.getElementById('react-app')
// );

ReactDOM.render(<App />,
  document.getElementById('react-app')
);



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
