import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import LayoutStore from '../../stores/Layout/LayoutStore'

interface IHomepageProps {

}

export class Homepage extends React.Component<RouteComponentProps<IHomepageProps>, {}> {
    componentDidMount() {
        LayoutStore.setTitle("Trang chủ")
    }
    
    render() {
        return (
            <div>
                day la homepage
            </div>
        );
    }
}