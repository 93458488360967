import * as React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import * as Utils from './utils/env'
import { Logout } from './layouts/Logout'
import { Layout } from './layouts/Layout'
import { Homepage } from './layouts/Homepage'
// import { Loading } from './layouts/Layout/loading'
import { ListSource } from './sceens/ListSource'
import { ListExpect } from './sceens/ListExpect'
import { ListNote } from './sceens/ListNote'
import { TicketLog } from './sceens/TicketLog'
import Middleware from './middleware'

const history = Utils.history

export const routes = <Router history={history}>
    <Middleware>
        <Layout routerHistory={history}>
            <Switch>
                <Route exact path='/' component={Homepage} />
                <Route exact path='/list-source' render={routerProps => {
                    return <ListSource {...routerProps} />
                }} />
                <Route exact path='/list-expect' render={routerProps => {
                    return <ListExpect {...routerProps} />
                }} />
                <Route exact path='/list-note' render={routerProps => {
                    return <ListNote {...routerProps} />
                }} />
                <Route exact path='/list-log' render={routerProps => {
                    return <TicketLog {...routerProps} />
                }} />
                <Route path='/log-out' component={Logout} />
                <Route exact path='/site' render={() => (<Redirect to={'/'} />)} />
            </Switch>
        </Layout>
    </Middleware>
</Router>