import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import * as Interfaces from '../../interfaces'
import LayoutStore from '../../stores/Layout/LayoutStore'
import * as Utils from '../../utils/env'
import { utils } from 'xlsx/types';
import * as FormatHelpers from '../../utils/FormatHelpers'
import './index.css'

interface TicketLogProps {

}

interface TicketLogStates {
    listLog: Interfaces.ILog[]
    page: number,
    page_size: number,
    total: number,
    isLoading: boolean
}

export class TicketLog extends React.Component<RouteComponentProps<TicketLogProps>, TicketLogStates> {
    constructor(props) {
        super(props);

        this.state = {
            listLog: [],
            page: 1,
            page_size: 20,
            total: 0,
            isLoading: false
        }
    }

    componentWillMount() {
        this.registerEventScroll()
    }

    async componentDidMount() {
        LayoutStore.setTitle("Log Ticket được bắn qua Haraworks")
        this._getListLog()
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', null)
    }

    private _getListLog = async () => {
        let { page, page_size } = this.state
        // HrvComponents.ShowWait.onStart()
        let rsp = await Utils.api_call_get_internal(`tfex/allLog?page=${page}&page_size=${page_size}`, '')
        if (rsp) {
            this.setState({
                listLog: [...rsp.data],
                total: rsp.total
            })
        }

        // HrvComponents.ShowWait.onEnd()
    }

    private registerEventScroll = () => {
        document.addEventListener('scroll', this.handleOnScroll)
    }

    private handleOnScroll = e => {
        let { listLog, total, page, page_size } = this.state
        if (listLog.length < total && ((window.innerHeight + window.scrollY) >= document.body.scrollHeight)) {
            // HrvComponents.ShowWait.onStart()
            this.setState({
                page: this.state.page + 1
            }, () => this._getListLog())
        }

        // HrvComponents.ShowWait.onEnd()
    }

    private renderStatus = (status: Interfaces.ELogStatus) => {
        let obj = {
            [Interfaces.ELogStatus.SUCCESS]: 'Thành công',
            [Interfaces.ELogStatus.FAILURE]: 'Thất bại',
        }
        return obj[status] || ""
    }

    render() {
        let { listLog } = this.state
        return <div style={{ margin: '40px' }}>
            <h2 style={{ marginBottom: '15px', marginTop: '30px', marginLeft: '15px' }}>Hoạt động gần đây đây đây</h2>
            <div>
                {listLog.map((it, index) => {
                    let colorIcon = it.status == Interfaces.ELogStatus.SUCCESS ? '#30BF94' : '#FF7B7B'
                    return <div key={Utils.getNewGuid()} className='scdp-log-item'>
                        <span style={{ display: 'flex', marginRight: '8px', alignItems: 'center', justifyContent: 'center', backgroundColor: colorIcon, borderRadius: '50%', width: '24px', height: '24px', minWidth: '24px' }}>
                            {Utils.Icon.boy(16, '#fff')}
                        </span>
                        <div>
                            <div><b>Id: </b>{it._id}</div>
                            <div><b>Trạng thái: </b><span style={{ color: colorIcon }}>{this.renderStatus(it.status)}</span></div>
                            <div><b>Id Ticket App: </b>{it.tch_ticket_id}</div>
                            <div><b>Id Ticket Haraworks: </b>{it.hrw_ticket_id}</div>
                            <div><b>Data: </b>{it.data}</div>
                        </div>
                        <span style={{ marginLeft: 'auto', color: "#808080" }}>{it.created_at && FormatHelpers.getDateTimeFromDate(it.created_at)}</span>
                    </div>
                })}
            </div>
        </div>
    }
}