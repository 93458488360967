import { action, observable } from "mobx";

class LayoutStore {
    @observable title = "" as string

    @action setTitle(str: string) {
        this.title = str
    }
}

export default new LayoutStore()