import * as moment from 'moment';
import * as numeral from 'numeral';
numeral.register('locale', 'vi', {
    delimiters: {
        thousands: ',',
        decimal: '.'
    },
    abbreviations: {
        thousand: ' nghìn',
        million: ' triệu',
        billion: ' tỷ',
        trillion: ' nghìn tỷ'
    },
    ordinal: function () {
        return '.';
    },
    currency: {
        symbol: '₫'
    }
})

export const getDate = (unix: number): Date => {
    return new Date(unix)
}

export const getDatetoISOString = (date: Date): string => {
    return moment.default(date).toISOString()
}

export const isValidISOString = (isoString: string, defaultFormat: string = 'DD/MM/YYYY hh:mm A') => {
    return moment.default(isoString, defaultFormat, true).isValid()
}

export interface IOption {
    display: string,
    value: number
}

export const getDateShort = (inputDate: Date, dafaultFormat: string = 'DD/MM/YYYY hh:mm A'): string => {
    return moment.default(inputDate).format(dafaultFormat)
}

export const getDatetimeFormat = (inputDate: Date, dafaultFormat: string = 'L'): string => {
    return moment.default(inputDate).format(dafaultFormat)
}

export const getDateTimeFromDate = (inputDate: Date, fromNow: boolean = false, defaultFormat: string = 'DD/MM/YYYY hh:mm A', isRemoveFormatTime: boolean = true): string => {
    if (isRemoveFormatTime) {
        return formatDateWithoutEarly(inputDate, defaultFormat)
    }
    return formatDate(inputDate, fromNow, defaultFormat, true)
}

const formatDateWithoutEarly = (inputDate: Date, defaultFormat: string = 'DD/MM/YYYY hh:mm A'): string => {
    let date = new Date(inputDate);
    return MomentLocale(date).format(defaultFormat)
}

export const getDateTimeFromDate24H = (inputDate: Date, fromNow: boolean = false, defaultFormat: string = 'DD/MM/YYYY HH:mm:ss', isRemoveFormatTime: boolean = true): string => {
    if (isRemoveFormatTime) {
        return 'lúc ' + formatDateWithoutEarly(inputDate, defaultFormat)
    }
    return 'lúc ' + formatDate(inputDate, fromNow, defaultFormat, true)
}

const formatDate = (inputDate: Date, timeNow: boolean = false, defaultFormat: string = 'DD/MM/YYYY hh:mm A', isShowHour: boolean = false): string => {
    let date = new Date(inputDate);

    let today = new Date();
    today.setHours(date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());

    let yesterday = new Date();
    yesterday.setHours(date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());
    yesterday.setDate(today.getDate() - 1);

    let tomorrow = new Date();
    tomorrow.setHours(date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());
    tomorrow.setDate(today.getDate() + 1);

    let lastWeek = new Date();
    lastWeek.setHours(date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());
    lastWeek.setDate(today.getDate() - 7);

    let lastMonth = new Date();
    lastMonth.setHours(date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());
    lastMonth.setDate(today.getMonth() - 1);

    let result = null

    switch (date.getTime()) {

        case today.getTime():
            if (timeNow) {

                result = isShowHour ? MomentLocale(today).fromNow() + ' ' + MomentLocale(today).format('hh:mm A') : MomentLocale(today).fromNow();
            } else {
                result = isShowHour ? MomentLocale(today).calendar() + ' ' + MomentLocale(today).format('hh:mm A') : MomentLocale(today).calendar();
            }
            break;

        case yesterday.getTime():
            result = isShowHour ? MomentLocale(yesterday).calendar() + ' ' + MomentLocale(yesterday).format('hh:mm A') : MomentLocale(yesterday).calendar();
            break;

        case tomorrow.getTime():
            result = isShowHour ? MomentLocale(tomorrow).calendar() + ' ' + MomentLocale(tomorrow).format('hh:mm A') : MomentLocale(tomorrow).calendar();
            break;

        case lastWeek.getTime():
            result = isShowHour ? MomentLocale(lastWeek).calendar() + ' ' + MomentLocale(lastWeek).format('hh:mm A') : MomentLocale(lastWeek).calendar();
            break;

        case lastMonth.getTime():
            result = isShowHour ? MomentLocale(lastMonth).calendar() + ' ' + MomentLocale(lastMonth).format('hh:mm A') : MomentLocale(lastMonth).calendar();
            break;

        default:
            result = MomentLocale(inputDate).format(defaultFormat);
            break;
    }

    return result;
}

export const MomentLocale = (date: Date = new Date(), locale: string = 'vi'): moment.Moment => {
    let m = moment.default(date).locale(locale)
    m['_locale']._invalidDate = 'Ngày không hợp lệ'
    m['_locale']._meridiemParse = /AM|PM/i
    m['_locale']._longDateFormat.LT = 'hh:mm A'
    if (m['_locale']._abbr == 'vi') {
        m['_locale']._calendar.sameDay = '[Hôm nay]'
        m['_locale']._calendar.nextDay = '[Ngày mai]'
        m['_locale']._calendar.nextWeek = '[Tuần tới]'
        m['_locale']._calendar.lastDay = '[Hôm qua]'
        m['_locale']._calendar.lastWeek = '[Tuần rồi]'
    }
    return m
}

export const subStrTitle = (value: string, from?: number, length?: number) => {

    if (from == null || typeof from == "undefined") from = 0;
    if (length == null || typeof length == "undefined") length = 20;
    if (value != null && typeof value != "undefined") {
        if (value.length > length) {
            value = value.substr(from, length) + '...'
        }
    }
    return value;
}

export const removeVietnameseDiacriticalMark = (str: string): string => {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/Đ/g, "D");
    //str = str.replace(/[^0-9a-zàáạảãâầấậẩẫăằắặẳẵèéẹẻẽêềếệểễìíịỉĩòóọỏõôồốộổỗơờớợởỡùúụủũưừứựửữỳýỵỷỹđ\s]/gi, ''); //remove all symbols which is not numeric and alphabet
    //str = str.replace(/\W+/g, ' ');
    str = str.replace(/\s/g, '_'); //change space to _
    return str;
}

export const isNumber = (value: any): boolean => {
    return !isNaN(parseFloat(value)) && isFinite(value);
}

export const FormatMoney = (value: any, format: string = '0,0 $'): string => {
    return numeral(value).format(format)
}

export const ClearFormatMoney = (value: any): string => {
    return numeral(value)._value
}

export const FormatNumber = (value: any, format: string = '0,0'): string => {
    return numeral(value).format(format)
}

export const baseSettingMoment = () => {
    moment.relativeTimeThreshold('s', 60);
    moment.relativeTimeThreshold('m', 60);
    moment.relativeTimeThreshold('h', 24);

    moment.updateLocale('vi', {
        months: ['Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5', 'Tháng 6', 'Tháng 7', 'Tháng 8', 'Tháng 9', 'Tháng 10', 'Tháng 11', 'Tháng 12'],
        weekdays: ['Chủ nhật', 'Thứ hai', 'Thứ ba', 'Thứ tư', 'Thứ năm', 'Thứ sáu', 'Thứ bảy'],
        longDateFormat: {
            LT: "hh:mm A",
            LTS: "hh:mm:ss A",
            L: "DD/MM/YYYY",
            LL: "Do MMMM YYYY",
            LLL: "Do MMMM YYYY LT",
            LLLL: "dddd, Do MMMM YYYY LT"
        },
        calendar: {
            sameDay: "[Hôm nay] LT",
            nextDay: "[Ngày mai] LT",
            nextWeek: "L LT",
            lastDay: "[Hôm qua] LT",
            lastWeek: `L LT`,
            sameElse: `L LT`
        },
        relativeTime: {
            future: "%s trước",
            past: "%s trước",
            s: 'vài giây',
            ss: '%d giây',
            m: "1 phút",
            mm: "%d phút",
            h: "một giờ",
            hh: "%d giờ",
            d: "một ngày",
            dd: "%d ngày",
            M: "một tháng",
            MM: "%d tháng",
            y: "một năm",
            yy: "%d năm"
        },
        meridiem: function (hour, minute, isLowercase) {
            return hour < 12 ? 'SA' : 'CH';
        },
        invalidDate: "Ngày không hợp lệ"
    });
}
export const MomentLocaleCalendar = (date: Date = new Date(), format?: string, calendarFormatChange?: string): string => {
    let nowTime = new Date();
    baseSettingMoment();

    if (format) {
        return moment.default(new Date(date)).format(format);
    }

    let inOneHour = moment.default(nowTime).diff(moment.default(date), 'minutes');
    if (inOneHour >= 0 && inOneHour < 60) {
        return moment.default(date).fromNow();
    }

    return moment.default(date).calendar();
}
export const getDuration = (fromDate: Date, toDate: Date, type: string = 'days'): number => {
    let start = moment.default(toDate)
    let end = moment.default(fromDate)
    let result = start.diff(end, 'days')
    return result
}
export const isNullOrEmpty = (str: string): boolean => {
    if (str == null || str === '' || str == undefined)
        return true
    return false;
}
export const FormatDateTime = (inputDate: any, format: string = ""): string => {
    if (isNullOrEmpty(format)) {
        return MomentLocaleCalendar(inputDate);
    }
    else {
        return MomentLocaleCalendar(inputDate);
    }
}
export const FormatDateTimeCustom = (inputDate: any, format: string = "DD/MM/YYYY hh:mm A", calendarFormatChange?: string): string => {
    return MomentLocaleCalendar(inputDate, format, calendarFormatChange);
}

export const FormatDateTimeNoHour = (date: any): string => {
    baseSettingMoment();

    moment.updateLocale("vi", {
        calendar: {
            sameDay: "[Hôm nay]",
            nextDay: "[Ngày mai]",
            nextWeek: "DD/MM/YYYY",
            lastDay: "[Hôm qua]",
            lastWeek: "DD/MM/YYYY",
            sameElse: "DD/MM/YYYY"
        }
    })

    return moment.default(date).calendar();
}

export const ConvertStringToDateTime = (datetime: string, format: string): Date => {
    if (format == 'DD/MM/YYYY' || format == 'MM/DD/YYYY' || format == 'YYYY-MM-DD') {
        let time = convertShortDate(datetime, format);

        return time;
    }
    if (format == 'DD/MM/YYYY hh:mm:ss A' || format == 'MM/DD/YYYY hh:mm:ss A' || format == 'YYYY-MM-DD hh:mm:ss A') {
        let time = convertFullTime(datetime, format);

        return time;
    }

    return null;
}

export const ConvertShortDateByFullFormat = (datetime: string, format: string): Date => {
    if (format == 'DD/MM/YYYY hh:mm:ss A' || format == 'MM/DD/YYYY hh:mm:ss A' || format == 'YYYY-MM-DD hh:mm:ss A') {
        let shortDateFormat = format.split(' ')[0];

        let timeSplit = datetime.split(' ');

        return convertShortDate(timeSplit[0], shortDateFormat);
    }
    return null;
}

export const convertShortDate = (shortDate: string, format: string): Date => {
    if (format == 'DD/MM/YYYY') {
        let timeSplit = shortDate.split('/');
        if (timeSplit.length != 3)
            return null;
        try {
            let time = new Date(parseInt(timeSplit[2]), parseInt(timeSplit[1]) - 1, parseInt(timeSplit[0]));
            if (!isNaN(time.getTime()))
                return time;
            return null;
        }
        catch (err) {
            console.log(err);
            return null;
        }
    }
    if (format == 'MM/DD/YYYY') {
        let timeSplit = shortDate.split('/');
        if (timeSplit.length != 3)
            return null;
        try {
            let time = new Date(parseInt(timeSplit[2]), parseInt(timeSplit[0]) - 1, parseInt(timeSplit[1]));
            if (!isNaN(time.getTime()))
                return time;
            return null;
        }
        catch (err) {
            console.log(err);
            return null;
        }
    }
    if (format == 'YYYY-MM-DD') {
        let timeSplit = shortDate.split('-');
        if (timeSplit.length != 3)
            return null;
        try {
            let time = new Date(parseInt(timeSplit[0]), parseInt(timeSplit[1]) - 1, parseInt(timeSplit[2]));
            if (!isNaN(time.getTime()))
                return time;
            return null;
        }
        catch (err) {
            console.log(err);
            return null;
        }
    }
}
export const convertHour = (hour: string, meridiem: string) => {
    let hourSplit = hour.split(':');
    if (hourSplit.length != 3)
        return null;

    try {
        let hour = parseInt(hourSplit[0]);
        let min = parseInt(hourSplit[1]);
        let second = parseInt(hourSplit[2]);

        let meridiemSplit = meridiem.toLowerCase();

        if (meridiemSplit == 'am' || meridiemSplit == 'pm' || meridiemSplit == 'sa' || meridiemSplit == 'ch' || meridiemSplit == 'a') {
            if (meridiemSplit == 'pm' || meridiemSplit == 'ch') {
                hour = hour + 12;
            }

            return {
                hour: hour,
                min: min,
                second: second
            };
        }
        else
            return null;
    } catch (err) {
        console.log(err);
        return null;
    }
}

export const convertFullTime = (fullTime: string, format: string): Date => {
    let shortDateFormat = format.split(' ')[0];

    let timeSplit = fullTime.split(' ');
    if (timeSplit.length != 3)
        return null;
    let shortDateSplit = timeSplit[0];
    let shortDate = convertShortDate(shortDateSplit, shortDateFormat);
    if (shortDate) {
        let hourObject = convertHour(timeSplit[1], timeSplit[2]);
        if (hourObject) {
            let year = shortDate.getFullYear();
            let month = shortDate.getMonth();
            let day = shortDate.getDate();
            let hour = hourObject.hour;
            let min = hourObject.min;
            let second = hourObject.second;

            let time = new Date(year, month, day, hour, min, second);
            return time;
        }
        else
            return null;
    } else
        return null;
}

export const createUniqueIDFactory = (prefix: string) => {
    let index = 1;
    return () => `${prefix}${index++}`;
}

export const roundBy = (decimalPlaces, n) => {
    var scale = Math.pow(10, decimalPlaces);
    return Math.round(scale * n) / scale;
};

export const isInt = (n) => {
    return n % 1 === 0;
}