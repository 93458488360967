import * as React from 'react'

export const arrowHeader = (size?: number) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width={size + 'px'} height={size + 'px'} viewBox="0 0 16 16"><path d="M6.47 4L5.53 4.94L8.58333 8L5.53 11.06L6.47 12L10.47 8L6.47 4Z"></path></svg>
}
export const myForm = (size?: number) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width={size + 'px'} height={size + 'px'} viewBox="0 0 18 20"><path d="M16 2H11.82C11.4 0.84 10.3 0 9 0C7.7 0 6.6 0.84 6.18 2H2C0.9 2 0 2.9 0 4V18C0 19.1 0.9 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM9 1.75C9.22 1.75 9.41 1.85 9.55 2C9.67 2.13 9.75 2.31 9.75 2.5C9.75 2.91 9.41 3.25 9 3.25C8.59 3.25 8.25 2.91 8.25 2.5C8.25 2.31 8.33 2.13 8.45 2C8.59 1.85 8.78 1.75 9 1.75ZM2 4V18H16V4H2ZM9 5C7.35 5 6 6.35 6 8C6 9.65 7.35 11 9 11C10.65 11 12 9.65 12 8C12 6.35 10.65 5 9 5ZM8 8C8 8.55 8.45 9 9 9C9.55 9 10 8.55 10 8C10 7.45 9.55 7 9 7C8.45 7 8 7.45 8 8ZM3 15.47V17H15V15.47C15 12.97 11.03 11.89 9 11.89C6.97 11.89 3 12.96 3 15.47ZM9 13.88C7.69 13.88 6 14.44 5.31 15H12.69C12.01 14.44 10.31 13.88 9 13.88Z" fillRule="evenodd" clipRule="evenodd" /></svg>
}
export const needApproved = (size?: number) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width={size + 'px'} height={size + 'px'} viewBox="0 0 18 20"><path fillRule="evenodd" clipRule="evenodd" d="M11.82 2H16C17.1 2 18 2.9 18 4V18C18 19.1 17.1 20 16 20H2C1.86 20 1.73 19.99 1.6 19.97C1.21 19.89 0.86 19.69 0.59 19.42C0.41 19.23 0.26 19.02 0.16 18.78C0.06 18.54 0 18.27 0 18V4C0 3.72 0.06 3.46 0.16 3.23C0.26 2.99 0.41 2.77 0.59 2.59C0.86 2.32 1.21 2.12 1.6 2.04C1.73 2.01 1.86 2 2 2H6.18C6.6 0.84 7.7 0 9 0C10.3 0 11.4 0.84 11.82 2ZM4 8V6H14V8H4ZM14 12V10H4V12H14ZM11 14H4V16H11V14ZM9 1.75C9.41 1.75 9.75 2.09 9.75 2.5C9.75 2.91 9.41 3.25 9 3.25C8.59 3.25 8.25 2.91 8.25 2.5C8.25 2.09 8.59 1.75 9 1.75ZM2 18H16V4H2V18Z" /></svg>
}
export const permissionGroup_Plus_Circle = (size: number, fill: string = 'white') => {
    return <svg xmlns="http://www.w3.org/2000/svg" width={size + 'px'} height={size + 'px'} viewBox="0 0 16 16" fill={fill}><path fill-rule="evenodd" clip-rule="evenodd" d="M1.33325 8.00016C1.33325 4.32016 4.31992 1.3335 7.99992 1.3335C11.6799 1.3335 14.6666 4.32016 14.6666 8.00016C14.6666 11.6802 11.6799 14.6668 7.99992 14.6668C4.31992 14.6668 1.33325 11.6802 1.33325 8.00016ZM8.66658 8.66683H11.3333V7.3335H8.66658V4.66683H7.33325V7.3335H4.66658V8.66683H7.33325V11.3335H8.66658V8.66683Z" /></svg>
}
export const roundAction = (size?: number) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width={size + 'px'} height={size + 'px'} viewBox="0 0 24 24"><path fillRule="evenodd" clipRule="evenodd" d="M12 1V4C16.42 4 20 7.58 20 12C20 13.57 19.54 15.03 18.76 16.26L17.3 14.8C17.75 13.97 18 13.01 18 12C18 8.69 15.31 6 12 6V9L8 5L12 1ZM6 12C6 15.31 8.69 18 12 18V15L16 19L12 23V20C7.58 20 4 16.42 4 12C4 10.43 4.46 8.97 5.24 7.74L6.7 9.2C6.25 10.03 6 10.99 6 12Z" /></svg>
}
export const boy = (size: number, fill: string = '') => {
    return <svg xmlns="http://www.w3.org/2000/svg" width={size + 'px'} height={size + 'px'} fill={fill} viewBox="0 0 17 17"><path fillRule="evenodd" clipRule="evenodd" d="M15.0598 8.05833C15.0598 11.7383 12.0731 14.725 8.39311 14.725C4.71311 14.725 1.72644 11.7383 1.72644 8.05833C1.72644 4.37833 4.71311 1.39166 8.39311 1.39166C12.0731 1.39166 15.0598 4.37833 15.0598 8.05833ZM6.3931 9.55833C6.8531 9.55833 7.22643 9.185 7.22643 8.725C7.22643 8.265 6.8531 7.89166 6.3931 7.89166C5.93309 7.89166 5.55976 8.265 5.55976 8.725C5.55976 9.185 5.93309 9.55833 6.3931 9.55833ZM9.55976 8.725C9.55976 8.265 9.9331 7.89166 10.3931 7.89166C10.8531 7.89166 11.2264 8.265 11.2264 8.725C11.2264 9.185 10.8531 9.55833 10.3931 9.55833C9.9331 9.55833 9.55976 9.185 9.55976 8.725ZM12.0597 5.39162C10.1264 5.39162 8.43305 4.35162 7.49972 2.80495C7.78639 2.75828 8.08639 2.72495 8.39305 2.72495C10.3264 2.72495 12.0197 3.76495 12.9531 5.31162C12.6664 5.35828 12.3664 5.39162 12.0597 5.39162ZM3.33982 6.37201C4.47982 5.72535 5.35982 4.67201 5.77982 3.41201C4.63982 4.05868 3.75982 5.11201 3.33982 6.37201ZM13.5064 6.56471C13.6464 7.03804 13.7264 7.53804 13.7264 8.05804C13.7264 10.998 11.3331 13.3914 8.39309 13.3914C5.4531 13.3914 3.05976 10.998 3.05976 8.05804C3.05976 8.04667 3.06054 8.0353 3.0613 8.0242C3.06276 8.00274 3.06415 7.98228 3.05976 7.96471C4.7931 7.31137 6.18643 5.97137 6.88643 4.26471C8.11309 5.76471 9.97309 6.72471 12.0598 6.72471C12.5598 6.72471 13.0398 6.66471 13.5064 6.56471Z" /></svg>
}